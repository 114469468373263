<template>
    <div>
         <div class="header">
             <span @click="gouot"></span>
                <span>AGV库位管理</span>
             <span @click="islogout">退出</span>
        </div>
        <div class="content-c">
            <div class="list_left">
                <ul class="list_left_ul">
                    <li ref="selectFiles" :class="[idx == index ? 'cur list_left_ul_li':'list_left_ul_li']" v-for="(i,index) in leftlistDatas" :key="i.id" @click="changeHan(i,index)">{{i.l1Name}}- {{i.l2Name}}</li>
                </ul>
            </div>
            <div class="con_right">
                <div :class="[ids==index?'cur con_right_wei':'con_right_wei']" v-for="(i,index) in RightlistDatas" :key="i.id" @click="kuChangeHan(i,index)">{{listinfo.l2Name}}-{{i.name}}</div>
            </div>
        </div>
       
        <van-dialog v-model="show" class="dialogD" :before-close="onBeforeClose" :title="title" show-cancel-button @confirm="confirmHan(titNum)">
            <p style="text-align: center;" v-if="titNum == 1">您确定要清空{{listinfo.l2Name}}-{{kuweiData.name}}库位吗？</p>
            <div class="dialogD_dp">
                <van-field v-if="titNum == 2" v-model="value" label="占位物料种类" placeholder="请填写物料种类" />
            </div>
        </van-dialog>
         <van-popup
            v-model="istrue"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '25%',
            }"
        >
        <div class="bottom_p">
            <van-icon class="bottom_X" name="close" @click="istrue = false" />
        </div>
         <div class="bottom-btn" v-if="istrue">
            <van-button class="btn"  size="large" type="info" @click="occupyHan(1)">清空{{listinfo.l2Name}}-{{kuweiData.name}}库位</van-button>
            <van-button class="btn"  size="large" type="info" @click="occupyHan(2)" >占位{{listinfo.l2Name}}-{{kuweiData.name}}库位</van-button>
        </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                idx:0,
                ids:null,
                show:false,//左侧
                leftlistDatas:[],//左侧
                RightlistDatas:[],//右侧
                istrue:false,
                kuweiData:{},
                listinfo:{},
                title:'',
                titNum:'',
                value:"",
            }
        },
        mounted () {
            this.listData();
        },
        methods: {
            onBeforeClose(action, done){
                if (action === "confirm") {//点击确定按钮
                    return done(false);//阻止关闭
                } else {
                    return done()//允许关闭
                }
            },
            //
            confirmHan(num){
                let a= ''
                let obj= ''
                
                if(num == 2){
                    if(this.value){
                       obj = {id:this.kuweiData.id,Goods:this.value}
                        this.abc(obj)
                    }else{
                        this.$toast.fail('请填写物料种类')
                    }
                }else{
                    obj = {id:this.kuweiData.id,Goods:''}
                    this.abc(obj)
                }
                
               
            },
            abc(obj){
                this.$http.post('index.php/agv/UpdateStorageState',obj).then(res=>{
                    if(res.data.code == 0){
                       this.value = ''
                        this.show = false
                        this.istrue = false
                         this.$toast.success(res.data.msg)
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else{
                         this.$toast.fail(res.data.msg)
                    }
                })
            },
            //打开弹窗
            occupyHan(num){
                this.titNum = num
                this.title = num == 1 ? `温馨提示` : `请填写占用物料种类`
                this.show = true
            },
            //返回
            gouot() {
                this.$router.push('/AGV')
            },
            listData(){
                this.$http.post('index.php/agv/locationList').then(res=>{
                    if(res.data.code == 0){
                        this.leftlistDatas = res.data.data
                        this.changeHan(this.leftlistDatas[0],0)
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else{
                         this.$toast.fail(res.data.msg)
                    }
                }).catch((err)=>{
                    this.$toast.fail('请求出错！')
                })
            },
            changeHan(v,i){
                this.listinfo = v
                this.idx = i
                this.$http.post('index.php/agv/location2To3',{id:v.id}).then(res=>{
                    if(res.data.code == 0){
                        this.RightlistDatas = res.data.data
                        this.ids = null
                        this.istrue = false
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else{
                         this.$toast.fail(res.data.msg)
                    }
                })
            },
            kuChangeHan(v,i){
                console.log(v)
                this.istrue = true
                this.kuweiData = v
                this.ids = i
            }
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.content-c{
    padding-top: 50px;
    display: flex;
    height: calc(100vh - 51px);
    // border-bottom: 1px solid #eee;
    .list_left{
        flex:1;
        border-right: 1px solid #000;
        overflow: scroll;
        .list_left_ul{
            .list_left_ul_li{
                text-align: center;
                font-weight: 700;
                padding: 10px;
                line-height: 50px;
                border-bottom: 1px solid #000;
                &.cur{
                    background:#409EFF ;
                    color: #fff;
                }
            }
        }
    }
    .con_right{
        flex:2;
        padding: 10px;
        overflow: scroll;
        display: grid;
        grid-template-columns: repeat(2, 49%);   //（每行5个  20%的框）
        grid-template-rows: 150px  150px;
        .con_right_wei{
            width: 90%;
            height: 130px;
            border: 1px solid #000;
            margin-bottom: 10px;
            margin-left: 15px;
            text-align: center;
            line-height: 130px;
            font-weight: bold;
            font-size: 35px;
            border-radius: 8px;
            &.cur{
                border: 0;
                background:#409EFF ;
                color: #fff; 
               
            }
        }
    }
}
.bottom-btn{
    // margin-top: 20px;
    padding: 15px;
    .btn{
        margin: 15px 0;
    }
}
.bottom_p{
    width: 100%;
        position: relative;
    .bottom_X{
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 25px;

    }
    margin-bottom: 10px;
}
.dialogD{
    padding: 20px;
    .dialogD_dp{
        margin: 20px 0;
    }
}
</style>